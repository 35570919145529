
import hljs from 'highlight.js/lib/core';
import python from 'highlight.js/lib/languages/python';
import 'highlight.js/styles/vs.css';

export default function SnippetPy(props) {

    hljs.registerLanguage('python', python);

    const highlightedCode = hljs.highlight(
        props.code,
        { language: 'python' }
    );

    console.log(highlightedCode);

    return (
        <div className="code-snippet" draggable="true">
            <pre>
                {/* if you xxs my site, i respect you i guess */}
                <code dangerouslySetInnerHTML={{ __html: highlightedCode.value }} />
            </pre>
        </div>
    )
}

