
import SnippetPy from "../components/SnippetPy";
import Unipage from "../templates/Unipage";

export default function Functions() {

    const starter =
`def verse1():
    print("Hello")

def verse2():
    print("Goodbye")

if __name__ == "__main__":
    verse1()
    verse2()
`;

    const verseCode =
`def verse1():
    print("")

def verse2():
    print("")

def verse3():
    print("")

def verse4():
    print("")

def verse5():
    print("")

def verse6():
    print("")

if __name__ == "__main__":
    verse1()
    verse2()
    verse3()
    verse4()
    verse5()
    verse6()
`

    const lineCode =
`def verse1():
    print("Tsuyoku nareru riyuu wo shitta")
    print("Boku wo tsurete susume")

def verse2():
    print("Dorodarake no soumatou ni you")
    print("Kowabaru kokoro furueru te wa")
    print("Tsukamitai mono ga aru")
    print("Sore dake sa")

def verse3():
    print("Yoru no nioi ni (I'll spend all thirty nights)")
    print("Sora nirandemo (Staring into the sky)")
    print("Kawatte ikeru no wa jibun jishin dake")
    print("Sore dake sa")

def verse4():
    print("Tsuyoku nareru riyuu wo shitta")
    print("Boku wo tsurete susume")

def verse5():
    print("Dou shitatte!")

def verse6():
    print("Kesenai yume mo tomarenai ima mo")
    print("Dareka no tame ni tsuyoku nareru nara")
    print("Nando demo tachiagare")
    print("Sekai ni uchinomesarete makeru imi wo shitta")
    print("Guren no hana yo sakihokore")
    print("Unmei wo terashite")

if __name__ == "__main__":
    verse1()
    verse2()
    verse3()
    verse4()
    verse5()
    verse6()
`

    const nameEqMain =
`def my_function():
    print("Hello")

# Entry-point for the program
if __name__ == "__main__":
    # The last line below this one runs the code inside my_function
    # This is known as "calling" the function
    my_function()
`


    const funcEx =
`def function_name():
    print("code is tabbed under the function")
`

    return (<Unipage
        content={
            <>
                <h2>Display Song Lyrics Using Python</h2>

                <p>Goal: Use functions to break code into managable chunks.</p>

                <h3>Starter Code</h3>
                <p>Type the following code into your IDE. This will be the starter code for our program.</p>
                <p><SnippetPy code={starter} /></p>
                <p>Download the starter code: <a href="/starter_code/functions.py">functions.py</a></p>

                <h4>Parts of a Function Declaration</h4>

                <p>In Python, a function is <b>declared</b> using the <code>def</code> keyword.</p>
                <p>After the <code>def</code> keyword is the <b>function name</b>. You can name the function (almost) anything you want.</p>
                <p>
                    <ul>
                        <li>Function names must start with a letter.</li>
                        <li>Function names cannot contain spaces. Use underscores <code>_</code> instead.</li>
                    </ul>
                </p>
                <p>At the end is a pair of parentheses <code>()</code> and a colon <code>:</code></p>

                <p><SnippetPy code={funcEx}/></p>

                <h3>Step 1. Look up the lyrics to a song </h3>
                <p>
                    Look up the lyrics to a song. You will write a program to print the lyrics of this song to the terminal. <b>You can choose any song you would like as long as it's not the same song as my example.</b> For my example, I used <a href="https://www.animesonglyrics.com/kimetsu-no-yaiba/gurenge">this</a> song from Demon Slayer.
                </p>

                <h3>Step 2. Make a new function for each <b>verse</b> of the song</h3>

                <p>Here's what that looks like in my example.</p>
                <p>You should make as many functions as you have verses in your song.</p>
                <p><SnippetPy code={verseCode} /></p>

                <h4>Useful Observations</h4>

                <p>First, notice that <b>each function needs to have a different name.</b> If we try to declare two functions with the same name, the Python interperted will become confused about which is which.</p>

                <p>
                    Second, notice that <b>the way we show code belongs to a function is using tabs.</b> Python is very strict in enforcing its rules around tabs.
                    <ul>
                        <li>
                            Each function declaration should be flat on the leftmost side of the screen.
                        </li>
                        <li>
                            Each line of code (for now) should be tabbed exactly once under its parent function.
                        </li>
                    </ul>
                </p>

                <h3>Step 3. Make a new print statement for each line of the song</h3>

                <p><SnippetPy code={lineCode} /></p>
                <p></p>

                <h3>What is that? if __name__ == "__main__"</h3>

                <p>The code under this line is the starting point for the program.</p>
                <p>It's common for the first code the program runs to be a function call.</p>
                <p><SnippetPy code={nameEqMain} /></p>

                <h3>Run your code</h3>
                <p>First, on the upper part of the screen, hover over Terminal. Then select New Terminal.</p>
                <p>
                    <img width="800 px" src="/python_imgs/terminal_functions.png" />
                </p>
                <p>Second, click into the terminal window and navigate to the directory that has your code file.</p>
                <p><ul>
                    <li>Use the command <code>cd</code> followed by a space and the name of the folder you want to enter.</li>
                    <li><code>cd</code> stands for <code>c</code>hange <code>d</code>irectory.</li>
                </ul></p>
                <p>
                    <img width="800 px" src="/python_imgs/cd_functions.png" />
                </p>
                <p>Finally, run the command <code>python</code> followed by a space and the full name of the code file to run.</p>
                <p>
                    <img width="800 px" src="/python_imgs/running_functions.png" />
                </p>
            </>
        }
    />);

}