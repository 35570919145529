
import SnippetPy from "../components/SnippetPy";
import Unipage from "../templates/Unipage";

export default function TurtleGraphics() {

    return (<Unipage
        content={
            <>
                <h2>Python Index</h2>
                <ol>
                    <li><a href="/python/functions">Functions</a></li>
                    <li><a href="/python/turtle-graphics">Intro Turtle Graphics</a></li>
                    <li><a href="/python/turtle-art">Turtle Art</a></li>
                    <li><a href="/python/if-and-input">If Statements</a></li>
                </ol>
            </>
        }
    />);

}