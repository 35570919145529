
import "./RedSidebar.css"

export default function RedSidebar(props) {

	return (
		<div className="h-100 red-sidebar">
			{props.content}
		</div>
	);

}