
import SnippetPy from "../components/SnippetPy";
import Unipage from "../templates/Unipage";

export default function TurtleName() {

    return (<Unipage
        content={
            <>
                <h2>Turtle Art</h2>
                <p>
                    <img height="100 px" src="/python_imgs/navarro_turtle.png" />
                </p>
                <p>
                    <a href="/starter_code/drawing_name.py">drawing_name.py</a>
                </p>
                <p>
                    <a href="https://docs.google.com/document/d/1rVawEZuuQ7Fsbas7Tn__afcRpg49waedAbWOU22NZck/">Turtle Drawing Guide</a>
                </p>

                <h3>Submit on BLEND</h3>
                <p>
                    You must give me permission to view your repository on GitHub. Submit the link to your repository.
                </p>
                <p>
                    My username is: noy-nac
                </p>
                <p>
                    <ul>
                        <li><a href="https://aisdblend.instructure.com/courses/553197/assignments/10227987">CS 1</a></li>
                        <li><a href="https://aisdblend.instructure.com/courses/553203/assignments/10227992">AP CS P</a></li>
                    </ul>
                </p>
                <h3>Requirements</h3>
                <p>
                    <ul>
                        <li>Code should run</li>
                        <li>Use two or more colors</li>
                        <li>Use the fill command</li>
                        <li>Pick up and put down the pen</li>
                        <li>Use circles</li>
                        <li>Use functions to organize your code</li>
                        <li>Add Mx. Mooney as a collaborator under the repository settings</li>
                        <li>Be a complete, unified design</li>
                    </ul>
                </p>
            </>
        }
    />);

}