
import Copyright from "../components/Copyright";
import NavBar from "../components/nav/NavBar";
import Stripe from "../components/splash/Stripe";


export default function Unipage(props) {
	return (
		<div className="container-flex">
			<div className="row m-0 p-0">
				<Stripe color="#f0e060" height="1.5rem"/>
				<div className="col-xl-9 col-lg-10 col-md-12 col-sm-12 col-xs-12 m-0 px-2 px-sm-4 px-lg-5 my-0 p-0">
					<NavBar />
					<div className="px-2 px-sm-4 px-lg-5">
						{props.content}
						<Copyright />
					</div>
				</div>
			</div>
			<div className="row m-0">
				<Stripe color="#f0e060" height="1.5em" />
			</div>
		</div>
	);
}