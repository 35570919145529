
import "../LivingCalendar.css"

export default function LCal24SpH1(props) {

	const headers = [
		"", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"
	];
	const body = [
		[
			["Wk. ", 1, "", "", [
				<></>,
			]],
			["Aug. ", 19, "", "", [
				<><div className="cal-holi">STAFF DEV.</div></>,
			]],
			[null, null, null, "B1", [
				<><div className="cal-alert">First day of classes</div></>,
			]],
			[null, null, null, "A1", [
				<></>,
			]],
			[null, null, null, "B2", [
				<></>,
			]],
			[null, null, null, "A2", [
				<></>,
			]],
		],
		[
			["Wk. ", 2, "", "", [
				<></>,
			]],
			["", 26, null, "A3", [
				<></>,
			]],
			[null, null, null, "B3", [
				<></>,
			]],
			[null, null, null, "A4", [
				<></>,
			]],
			[null, null, null, "B4", [
				<>Fund CS -- Finish Parts of a Computer</>,
				<><a href="https://docs.google.com/presentation/d/11evv1nphVCFhK3Qu9BEz71RAzcXLr-HCPcmm7kZWwcw/">AP CS P</a> -- Using Git, Using Objects - Turtle Graphics</>,
			]],
			[null, null, null, "A5", [
				<><a href="https://docs.google.com/document/d/11bs8WCI5oMMQz9PZn15Vusn6CDYZPL4_KI6LfzlTaw8/edit?usp=sharing">Turtle</a></>,
			]],
		],
		[
			["Wk. ", 3, "", "", [
				<></>,
			]],
			["Sept. ", 2, "", "", [
				<><div className="cal-holi">LABOR DAY</div></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 4, "", "", [
				<></>,
			]],
			["", 9, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 5, "", "", [
				<></>,
			]],
			["", 16, null, null, [
				<></>
			]],
			[null, null, null, null, [
				<>Fund CS -- <a href="https://studio.code.org/s/fcs2-beta-2024/lessons/4/levels/1">Pixels</a></>,
				<>Fund CS -- <a href="https://studio.code.org/s/fcs2-beta-2024/lessons/5/levels/1">Binary</a></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<>Fund CS -- <a href="https://studio.code.org/s/fcs3-beta-2024/lessons/1/levels/1">Fish</a></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 6, "", "", [
				<></>,
			]],
			[null, 23, null, null, [
				<>CS 1/P -- <a href="https://classicreload.com/zork-i.html#">Zork</a></>,
				<>CS 1/P -- <a href="https://www.pcjs.org/software/pcx86/game/infocom/zork1/">Zork</a></>, 
				<>CS 1/P -- <a href="https://textadventures.co.uk/games/play/5zyoqrsugeopel3ffhz_vq">Zork</a></>,
				<>CS 1/P -- <a href="https://www.google.com/search?client=firefox-b-1-d&q=zork+map">Zork Map</a></>,
			]],
			[null, null, null, null, [
				<>Fund CS -- <a href="https://studio.code.org/s/fcs3-beta-2024/lessons/6/levels/1">AI Lab - Start Here</a></>,
				<>Fund CS -- <a href="https://www.youtube.com/watch?v=bGJ05vdI3xA">Intro to AI Lab</a></>,
				<>Fund CS -- <a href="https://www.youtube.com/watch?v=HcCtdaiMWPQ">Training Testing w AI Lab</a></>,
				<>Fund CS -- <a href="https://www.youtube.com/watch?v=R9OHn5ZF4Uo">How AI Works</a></>,
	
			]],
			[null, null, null, null, [
				<>CS 1/P -- <a href="https://www.youtube.com/watch?v=CGRKqnoQGgM">Parameters</a></>,
				<>CS 1/P -- <a href="https://www.youtube.com/watch?v=LWdsF79H1Pg">Return</a></>,
			]],
			[null, null, null, null, [
				<>Fund CS -- <a href="https://studio.code.org/s/fcs3-beta-2024/lessons/12/levels/1">Safari</a></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 7, "", "", [
				<></>,
			]],
			[null, 30, null, null, [
				<></>,
			]],
			["Oct. ", 1, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, "", "", [
				<><div className="cal-holi">STAFF DEV.</div></>,
			]],
			[null, null, "", "", [
				<><div className="cal-holi">STAFF DEV.</div></>,
				<><div className="cal-sp">Rosh HaShanah</div></>,
			]],
		],
		[
			["Wk. ", 8, "", "", [
				<></>,
			]],
			[null, 7, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<>Fund CS -- <a href="https://studio.code.org/s/csd2-2023/lessons/1/levels/1">Website Templates</a></>,
				<>Fund CS -- <a href="https://studio.code.org/s/csd2-2023/lessons/2/levels/1">Website Builder</a></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 9, "", "", [
				<></>,
			]],
			[null, 14, "", "", [
				<><div className="cal-holi">STAFF DEV.</div></>,
				<><div className="cal-sp">Indigenous Peoples' Day</div></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<><div className="cal-alert">End of grading period</div></>,
			]],
		],

	]

	return (
		<>
			<table className="table table-bordered">
				<thead>
					<tr>
						{
							headers.map(colHead => <th className="mc-head" scope="col">{colHead}</th>)
						}
					</tr>
				</thead>
				{
					body.map(bodyRow =>

						<tbody>
							<tr>
								{
									bodyRow.map((rc, i, a) => {
										return (<td>
											<div className="mc-title">{rc[0] = (rc[0] == null ? "" : rc[0])}
												{rc[1] = (rc[1] == null ? a[i > 0 ? i - 1 : 0][1] + 1 : rc[1])}
												{rc[2] = (rc[2] == null ? " -- Day " : rc[2])}
												{rc[3] = (rc[3] == null ? "" : rc[3])}</div>
											{
												rc[4].map((value, index, array) => {
													return <div className="mc-data">{value}</div>
												})
											}
										</td>)
									})
								}

							</tr>
						</tbody>
					)
				}
			</table>
		</>
	);

}