
import NavBar from "../components/nav/NavBar";
import BigName from "../components/splash/BigName";
import RedSidebar from "../components/RedSidebar";
import EmbededAccordion from "../components/EmbededAccordion";
import Stripe from "../components/splash/Stripe";
import Copyright from "../components/Copyright";

export default function Bipage(props) {
	return (
		<div className="container-flex">
			<div className="row m-0">
				<div className="col-xl-3 col-lg-3 col-md-4 p-0" >
					<RedSidebar content={<>
						<Stripe color="#900000" height="1.5rem" />
						<div className="px-4">
							{/*<div className="py-4">
								<BigName size="2.5em" />
							</div>*/}
							<div>
								{props.left}
							</div>
						</div>
					</>}
					/>
				</div>

				<div className="col-xl-9 col-lg-9 col-md-8 p-0">
					<div className="row m-0">
						<Stripe color="#f0e060" height="1.5em" />
					</div>
					<div className="row m-0">
						<div className="col-xl-11 col-lg-11 col-md-12 col-sm-12 col-xs-12 p-0">
							<NavBar />
							<div className="px-2 px-sm-4 px-lg-5">
								{props.right}
								<Copyright />
							</div>
							
						</div>
					</div>
					
				</div>
			</div>
			<div className="row m-0">
				<div className="d-none d-md-inline col-xl-3 col-lg-3 col-md-4 p-0" >
					<Stripe color="#900000" height="1.5em" />
				</div>
				<div className="col-xl-9 col-lg-9 col-md-8 p-0">
					<Stripe color="#f0e060" height="1.5em" />
				</div>
			</div>
		</div>
	);
}