
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './Tabbed.css';

export default function Tabbed(props) {

    return (
        <Tabs selectedTabClassName="tab-select" disabledTabClassName="tab-disabled">
            <TabList className="tab-list">
            {
                props.tabs.map(tab => 
                    <Tab className="tab">{tab}</Tab>
                )
            }
            </TabList >
            {
                props.items.map(item =>
                    <TabPanel>
                        {item}
                    </TabPanel>
                )
            }
        </Tabs>
    );

}