
const navarro_contact_data = [
	{
		is_open: true,
		head_id: "personal-head",
		head: [
			<h4>Personal</h4>
		],
		body_id: "personal-info",
		body: [
			<pre>Mx. Canyon Mooney</pre>,
			<pre>Pronouns: they/them</pre>
		]
	},
	{
		is_open: true,
		head_id: "email-head",
		head: [
			<h4>Email</h4>
		],
		body_id: "email-info",
		body: [
			<h4>Work</h4>,
			<pre>canyon.a.mooney@austinisd.org</pre>,
		]
	},
	{
		is_open: false,
		head_id: "phone-head",
		head: [
			<h4>Phone</h4>
		],
		body_id: "phone-info",
		body: [
			<h4>Office</h4>,
			<pre>512.414.2514 x27340</pre>
		],
	},
	{
		is_open: false,
		head_id: "addr-head",
		head: [
			<h4>Location</h4>
		],
		body_id: "addr-info",
		body: [
			<pre>Room 30, Building E</pre>,
			<pre>Navarro High School</pre>,
			<pre>1201 Payton Gin Road</pre>,
			<pre>Austin, TX 78758</pre>,

		],
	}
];

const ahs_contact_data = [
	{
		is_open: true,
		head_id: "personal-head",
		head: [
			<h3>Personal</h3>
		],
		body_id: "personal-info",
		body: [
			<pre>Mx. Canyon Mooney</pre>,
			<pre>Pronouns: they/them</pre>
		]
	},
	{
		is_open: true,
		head_id: "email-head",
		head: [
			<h3>Email</h3>
		],
		body_id: "email-info",
		body: [
			<h4>Student Inquiries</h4>,
			<pre>canyon.a.mooney@austinisd.org</pre>,
		]
	},
	{
		is_open: false,
		head_id: "phone-head",
		head: [
			<h3>Phone</h3>
		],
		body_id: "phone-info",
		body: [
			<h4>Office</h4>,
			<pre>512.841.2021</pre>
		],
	},
	{
		is_open: false,
		head_id: "addr-head",
		head: [
			<h3>Location</h3>
		],
		body_id: "addr-info",
		body: [
			<pre>Room 301</pre>,
			<pre>Austin High School</pre>,
			<pre>1715 W. Cesar Chavez St.</pre>,
			<pre>Austin, TX 78703</pre>,

		],
	}
];

const contact_data = [
	{
		is_open: true,
		head_id: "personal-head",
		head: [
			<h4>Personal</h4>
		],
		body_id: "personal-info",
		body: [
			<pre>Mx. Canyon Mooney</pre>,
			<pre>Pronouns: they/them</pre>
		]
	},
	{
		is_open: true,
		head_id: "email-head",
		head: [
			<h4>Email</h4>
		],
		body_id: "email-info",
		body: [
			<h5>Work (UT)</h5>,
			<pre>cmooney@utexas.edu</pre>,
			<h5>Personal</h5>,
			<pre>canyon.mooney@gmail.com</pre>
		]
	},
	{
		is_open: false,
		head_id: "phone-head",
		head: [
			<h4>Phone</h4>
		],
		body_id: "phone-info",
		body: [
			<h5>Mobile</h5>,
			<pre>512.217.1296</pre>
		],
	},
	{
		is_open: false,
		head_id: "addr-head",
		head: [
			<h4>Address</h4>
		],
		body_id: "addr-info",
		body: [
			<pre>2612 Guadalupe St #310</pre>,
			<pre>Austin TX 78705</pre>
		],
	}
];

export { ahs_contact_data, contact_data, navarro_contact_data };