
import "../LivingCalendar.css"

export default function LCal24SpH1(props) {

	const headers = [
		"", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"
	];
	const body = [
		[
			["Wk. ", 10, "", "", [
				<></>,
			]],
			["Oct. ", 28, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			["Nov. ", 1, "", "", [
				<><div className="cal-holi">DIWALI</div></>,
			]],
		],
		[
			["Wk. ", 11, "", "", [
				<></>,
			]],
			[null, 4, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<><div className="cal-holi">STAFF DEV.</div></>,
				<><div className="cal-sp">Election Day</div></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 12, "", "", [
				<></>,
			]],
			[null, 11, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 13, "", "", [
				<></>,
			]],
			[null, 18, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["-", "", "", "", [
				<></>,
			]],
			[null, 25, "", "", [
				<><div className="cal-alert">FALL BREAK</div></>,
			]],
			[null, null, "", "", [
				<><div className="cal-alert">FALL BREAK</div></>,
			]],
			[null, null, "", "", [
				<><div className="cal-alert">FALL BREAK</div></>,
			]],
			[null, null, "", "", [
				<><div className="cal-holi">THANKSGIVING</div></>,
			]],
			[null, null, "", "", [
				<><div className="cal-alert">FALL BREAK</div></>,
			]],
		],
		[
			["Wk. ", 14, "", "", [
				<></>,
			]],
			["Dec. ", 2, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 15, "", "", [
				<></>,
			]],
			[null, 9, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 16, "", "", [
				<></>,
			]],
			[null, 16, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<><div className="cal-alert">End of grading period</div></>,
			]],
		],
		[
			["-", "", "", "", [
				<></>,
			]],
			[null, 23, null, null, [
				<><div className="cal-alert">WINTER BREAK</div></>,
			]],
			[null, null, null, null, [
				<><div className="cal-alert">WINTER BREAK</div></>,
			]],
			[null, null, null, null, [
				<><div className="cal-holi">CHRISTMAS DAY</div></>,
			]],
			[null, null, null, null, [
				<><div className="cal-alert">WINTER BREAK</div></>,
			]],
			[null, null, null, null, [
				<><div className="cal-alert">WINTER BREAK</div></>,
			]],
		],

	]

	return (
		<>
			<table className="table table-bordered">
				<thead>
					<tr>
						{
							headers.map(colHead => <th className="mc-head" scope="col">{colHead}</th>)
						}
					</tr>
				</thead>
				{
					body.map(bodyRow =>

						<tbody>
							<tr>
								{
									bodyRow.map((rc, i, a) => {
										return (<td>
											<div className="mc-title">{rc[0] = (rc[0] == null ? "" : rc[0])}
												{rc[1] = (rc[1] == null ? a[i > 0 ? i - 1 : 0][1] + 1 : rc[1])}
												{rc[2] = (rc[2] == null ? " -- Day " : rc[2])}
												{rc[3] = (rc[3] == null ? "" : rc[3])}</div>
											{
												rc[4].map((value, index, array) => {
													return <div className="mc-data">{value}</div>
												})
											}
										</td>)
									})
								}

							</tr>
						</tbody>
					)
				}
			</table>
		</>
	);

}