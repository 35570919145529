
import "../LivingCalendar.css"

export default function LCal24SpH1(props) {

	const headers = [
		"", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"
	];
	const body = [
		[
			["Wk. ", 17, "", "", [
				<></>,
			]],
			["Jan. ", 6, "", "", [
				<><div className="cal-holi">STAFF DEV.</div></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 18, "", "", [
				<></>,
			]],
			[null, 13, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 19, "", "", [
				<></>,
			]],
			[null, 20, "", "", [
				<><div className="cal-holi">M.L.K. DAY</div></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 20, "", "", [
				<></>,
			]],
			[null, 27, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, "", "", [
				<><div className="cal-holi">STAFF DEV.</div></>,
				<><div className="cal-sp">Lunar New Year</div></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 21, "", "", [
				<></>,
			]],
			["Feb. ", 3, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 22, "", "", [
				<></>,
			]],
			[null, 10, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 23, "", "", [
				<></>,
			]],
			[null, 17, "", "", [
				<><div className="cal-holi">STAFF DEV.</div></>,
				<><div className="cal-sp">Presidents' Day</div></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 24, "", "", [
				<></>,
			]],
			[null, 24, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 25, "", "", [
				<></>,
			]],
			["Mar. ", 3, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 26, "", "", [
				<></>,
			]],
			[null, 10, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<><div className="cal-alert">End of grading period</div></>,
			]],
			[null, null, "", "", [
				<><div className="cal-holi">STAFF DEV.</div></>,
			]],
		],
		[
			["-", "", "", "", [
				<></>,
			]],
			[null, 17, "", "", [
				<><div className="cal-alert">SPRING BREAK</div></>,
			]],
			[null, null, "", "", [
				<><div className="cal-alert">SPRING BREAK</div></>,
			]],
			[null, null, "", "", [
				<><div className="cal-alert">SPRING BREAK</div></>,
			]],
			[null, null, "", "", [
				<><div className="cal-alert">SPRING BREAK</div></>,
			]],
			[null, null, "", "", [
				<><div className="cal-alert">SPRING BREAK</div></>,
			]],
		],
	]

	return (
		<>
			<table className="table table-bordered">
				<thead>
					<tr>
						{
							headers.map(colHead => <th className="mc-head" scope="col">{colHead}</th>)
						}
					</tr>
				</thead>
				{
					body.map(bodyRow =>

						<tbody>
							<tr>
								{
									bodyRow.map((rc, i, a) => {
										return (<td>
											<div className="mc-title">{rc[0] = (rc[0] == null ? "" : rc[0])}
												{rc[1] = (rc[1] == null ? a[i > 0 ? i - 1 : 0][1] + 1 : rc[1])}
												{rc[2] = (rc[2] == null ? " -- Day " : rc[2])}
												{rc[3] = (rc[3] == null ? "" : rc[3])}</div>
											{
												rc[4].map((value, index, array) => {
													return <div className="mc-data">{value}</div>
												})
											}
										</td>)
									})
								}

							</tr>
						</tbody>
					)
				}
			</table>
		</>
	);

}