
import "../LivingCalendar.css"

export default function LCal24SpH1(props) {

	const headers = [
		"", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"
	];
	const body = [
		[
			["Wk. ", 27, "", "", [
				<></>,
			]],
			["Mar. ", 24, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 28, "", "", [
				<></>,
			]],
			[null, 31, "", "", [
				<><div className="cal-holi">HOLIDAY</div></>,
			]],
			["Apr. ", 1, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 29, "", "", [
				<></>,
			]],
			[null, 7, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 30, "", "", [
				<></>,
			]],
			[null, 14, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, "", "", [
				<><div className="cal-holi">STAFF DEV.</div></>,
				<><div className="cal-sp">Good Friday</div></>,
			]],
		],
		[
			["Wk. ", 31, "", "", [
				<></>,
			]],
			[null, 21, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 32, "", "", [
				<></>,
			]],
			[null, 28, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			["May ", 1, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 33, "", "", [
				<></>,
			]],
			[null, 5, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 34, "", "", [
				<></>,
			]],
			[null, 12, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 35, "", "", [
				<></>,
			]],
			[null, 19, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
		],
		[
			["Wk. ", 36, "", "", [
				<></>,
			]],
			[null, 26, "", "", [
				<><div className="cal-holi">MEMORIAL DAY</div></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<></>,
			]],
			[null, null, null, null, [
				<><div className="cal-alert">Last day of classes</div></>,
			]],
			[null, null, "", "", [
				<><div className="cal-holi">STAFF DEV.</div></>,
			]],
		],
	]

	return (
		<>
			<table className="table table-bordered">
				<thead>
					<tr>
						{
							headers.map(colHead => <th className="mc-head" scope="col">{colHead}</th>)
						}
					</tr>
				</thead>
				{
					body.map(bodyRow =>

						<tbody>
							<tr>
								{
									bodyRow.map((rc, i, a) => {
										return (<td>
											<div className="mc-title">{rc[0] = (rc[0] == null ? "" : rc[0])}
												{rc[1] = (rc[1] == null ? a[i > 0 ? i - 1 : 0][1] + 1 : rc[1])}
												{rc[2] = (rc[2] == null ? " -- Day " : rc[2])}
												{rc[3] = (rc[3] == null ? "" : rc[3])}</div>
											{
												rc[4].map((value, index, array) => {
													return <div className="mc-data">{value}</div>
												})
											}
										</td>)
									})
								}

							</tr>
						</tbody>
					)
				}
			</table>
		</>
	);

}