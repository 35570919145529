/* I can moo and other reasons you should hire me */

import EmbededAccordion from "../components/EmbededAccordion";
import Picture from "../components/Picture";
import Bipage from "../templates/Bipage";

import LCal24FaH1 from "../cal/SY2425/LCal24FaH1";
import LCal24FaH2 from "../cal/SY2425/LCal24FaH2";
import LCal25SpH1 from "../cal/SY2425/LCal25SpH1";
import LCal25SpH2 from "../cal/SY2425/LCal25SpH2";

import Tabbed from "../templates/Tabbed";

import { navarro_contact_data } from "../data/Contact.js";


export default function SplashNavarro() {
	return (
		<Bipage
			left={
				<>
					<Picture url="./mooney-head.jpg" size="150em" />

					<h2 style={{ textAlign: "center" }}>Contact Info</h2>

					<EmbededAccordion data={navarro_contact_data}/>


				</>
			}
			right={
				<>
					<h1>
						Mx. Mooney's Computer Science Class!
					</h1>

					<p>
						Hi, my name is Canyon Mooney.
					</p>

					<h2>
						2024&ndash;2025 Course Calendar
					</h2>

					<p>
						<strong>Please do NOT print this calendar, as it WILL change!</strong>
					</p>

					<Tabbed
						tabs={[
							"1st Quarter",
							"2nd Quarter",
							"3rd Quarter",
							"4th Quarter",
						]}

						items={[
							<LCal24FaH1 />,
							<LCal24FaH2 />,
							<LCal25SpH1 />,
							<LCal25SpH2 />,
						]}
					/>
				</>
			}
		/>
	);
}