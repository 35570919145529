
import "./EmbededAccordion.css"
import Stripe from "./splash/Stripe";

export default function EmbededAccordion(props) {

    const items_map = props.data.map((content) =>
        
        <div className="emb-accd accordion-item">
            
            <div
                className="emb-accd accordion-header"
                id={content.head_id}
                >
                <button
                    className={content.is_open ? "emb-accd accordion-button" : "emb-accd accordion-button collapsed"}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#" + content.body_id}
                    aria-expanded={content.is_open ? "true" : "false"}
                    aria-controls={content.body_id}>
                    
                    {content.head}
                </button>
            </div>
            <div
                id={content.body_id}
                className={content.is_open ? "emb-accd accordion-collapse collapse show" : "emb-accd accordion-collapse collapse"}
                aria-labelledby={content.head_id}>
                <div className="accordion-body">
                    {content.body}
                </div>
                {/*<Stripe height="0.5em" color="#f0e060" />*/}
            </div>
            
        </div>
    );

    return (
        <div className="emb-accd accordion accordion-flush ">
            {items_map}
        </div>
    );

}