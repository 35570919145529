
import SnippetPy from "../components/SnippetPy";
import Unipage from "../templates/Unipage";

export default function TurtleGraphics() {

    return (<Unipage
        content={
            <>
                <h2>Elements of Turtle Graphics</h2>
                <p>Use this starter code. It can be downloaded here: <a href="/starter_code/turtle_graphics.py">turtle_graphics.py</a></p>
                <SnippetPy code={
`from turtle import Turtle

def my_function():
    bob = Turtle()
    bob.shape("turtle")
    bob.color("red")
    
    bob.screen.mainloop()

# This block should ALWAYS be the LAST part of your code
if __name__ == "__main__":
    my_function()
`
                } />

                <h3>Shapes</h3>
                <p>The simplest way to create a shape in Python Turtle is to have the turtle trace the outline of the shape.</p>
                <ul>
                    <li>When using Python Turtle, we take the perspective of the turtle themselves.</li>
                    <li>For example, to draw a square we would walk forward, turn, go forward, turn, and repeat two more times.</li>
                </ul>
                <p>The sequence of steps one would take to walk in a square is an example of an algorithm. </p>
                <p>
                    <img src="/python_imgs/turtle_red_square.png"/>
                </p>
                <p>
                    <SnippetPy code={
`from turtle import Turtle

def my_function():
    bob = Turtle()
    bob.shape("turtle")
    bob.color("red")
    bob.forward(50)
    bob.right(90)
    bob.forward(50)
    bob.right(90)
    bob.forward(50)
    bob.right(90)
    bob.forward(50)
    bob.screen.mainloop()

if __name__ == "__main__":
    my_function()
`
                    } />
                </p>
                <p></p>
                <h3>Filled Shapes</h3>
                <p>
                    To fill a shape, enclose the code tracing the shaping using <code>bob.begin_fill()</code> and <code>bob.end_fill()</code>.
                </p>
                <p>
                    You can use <code>bob.fillcolor("blue")</code> to change the fill color to blue.
                </p>
                <p>
                    <SnippetPy code={
`from turtle import Turtle

def my_function():
    bob = Turtle()
    bob.shape("turtle")
    bob.color("red")
    bob.fillcolor("blue")
    bob.begin_fill()
    bob.forward(50)
    bob.right(90)
    bob.forward(50)
    bob.right(90)
    bob.forward(50)
    bob.right(90)
    bob.forward(50)
    bob.end_fill()
    bob.screen.mainloop()

if __name__ == "__main__":
    my_function()
`
                    } />
                </p>
                <p>
                    <img src="/python_imgs/turtle_red_blue_square.png" />
                </p>
            </>
        }
    />);

}